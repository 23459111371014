import type { RouteManifest } from '@hms-kontoret/amber.types'

const manifest: RouteManifest = {
  name: 'Onboarding',
  route: {
    name: 'Onboarding',
    path: '/onboarding',
    meta: {
      routeTitle: 'Onboarding',
      isVisibleInNavMenu: false
    }
  },
  children: [
    {
      name: 'ConfirmProfile',
      remote: {
        module: 'onboarding'
      },
      route: {
        name: 'ConfirmProfile',
        path: 'confirm-profile',
        meta: {
          routeTitle: 'menus__title__onboarding',
          routeDescription: 'menu__description__onboarding'
        }
      }
    }
  ]
}

export default manifest
