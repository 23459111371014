import { defineStore } from 'pinia'
import { BeforeLeaveGuard } from '@hms-kontoret/amber.types/RouteGuards'

export const useRouterStore = defineStore('router', {
  state: () => ({
    beforeLeaveGuards: {} as Record<string, BeforeLeaveGuard> // Store guards by path
  }),

  actions: {
    /**
     * Register a new beforeLeave guard for a specific route.
     */
    registerBeforeLeaveGuard(path: string, guard: BeforeLeaveGuard) {
      this.beforeLeaveGuards[path] = guard
    },

    /**
     * Unregister the beforeLeave guard for a specific route.
     * @param path - The path to unregister the guard for.
     */
    unregisterBeforeLeaveGuard(path: string) {
      delete this.beforeLeaveGuards[path]
    },

    /**
     * Execute the beforeLeave guard that matches the current route path.
     * @param to - The path the user is navigating to.
     * @param from - The path the user is navigating from.
     * @returns `true` if navigation is allowed, `false` if blocked.
     */
    async executeGuard(to: string, from: string): Promise<boolean> {
      const guard = this.beforeLeaveGuards[from] // Get guard for the current route being left
      if (guard) {
        const result = await guard(to, from)
        return result !== false // Allow navigation if guard doesn't return false
      }
      return true // Allow navigation if no guard is registered
    }
  }
})
