import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'CustomerSupport',
  route: {
    name: 'CustomerSupport',
    path: '/customer-support',
    meta: {
      routeTitle: 'menus__title__customer_support',
      menuIcon: 'n-icon-message-bubble-user',
      isVisibleInNavMenu: true
    }
  },
  children: [
    {
      name: 'CustomerSupportMenuLandingPage',
      remote: {
        module: 'menus'
      },
      route: {
        name: 'CustomerSupportMenuLandingPage',
        path: '',
        meta: {
          routeTitle: 'menus__landingpage__title__customer_support',
          routeDescription: 'menu__description__customer_support'
        }
      }
    },
    {
      name: 'AdminAccountOverview',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AdminAccountOverview',
        path: 'admin-tools/overview',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__admin_account_overview',
          routeDescription: 'menu__description__admin_account_overview',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.ACCOUNT_ADMIN],
          menuIcon: 'n-icon-user'
        }
      }
    },
    {
      name: 'AdminAccountSupportAccess',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AdminAccountSupportAccess',
        path: 'admin-tools/support-access',
        alias: ['supportaccess'],
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__admin_account_support_access',
          routeDescription: 'menu__description__admin_account_support_access',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.ACCOUNT_ADMIN],
          menuIcon: 'n-icon-user'
        }
      }
    },
    {
      name: 'AdminAccountSupportUsers',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AdminAccountSupportUsers',
        path: 'admin-tools/support-users',
        alias: ['supportusers'],
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__admin_account_support_users',
          routeDescription: 'menu__description__admin_account_support_users',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.ACCOUNT_ADMIN],
          menuIcon: 'n-icon-user'
        }
      }
    },
    {
      name: 'CustomerSupportPanel',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'CustomerSupportPanel',
        path: 'control-panel/customer-support',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__customer_support_panel',
          routeDescription: 'menu__description__customer_support_panel',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.SUPPORT_ACCOUNT],
          menuIcon: 'n-icon-message-bubble-user'
        }
      }
    },
    {
      name: 'AnnualReport',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AnnualReport',
        path: 'control-panel/annual-report',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__annual_report',
          routeDescription: 'menu__description__annual_report',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.SUPPORT_ACCOUNT],
          menuIcon: 'n-icon-file-add-remove'
        }
      }
    },
    {
      name: 'AvonovaAccess',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AvonovaAccess',
        path: 'control-panel/avonova-access',
        meta: {
          isVisibleInNavMenu: false,
          routeTitle: 'menu__title__avonova_access',
          routeDescription: 'menu__description__avonova_access',
          isStandaloneRoute: true,
          menuIcon: 'n-icon-lock'
        }
      }
    },
    {
      name: 'Setup',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Setup',
        path: 'oppsett/oppsett',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__setup',
          routeDescription: 'menu__description__setup',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.SETUP],
          menuIcon: 'n-icon-hide'
        }
      }
    }
  ]
}

export default manifest
