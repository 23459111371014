import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'Settings',
  route: {
    name: 'Settings',
    path: '/settings',
    meta: {
      routeTitle: 'menus__title__settings',
      menuIcon: 'n-icon-cogwheel'
    }
  },
  children: [
    {
      name: 'CorporateAccounts',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'CorporateAccounts',
        path: 'konsern/konsernkontoer',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__item__concern_accounts',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.CORPORATE]
        }
      }
    },
    {
      name: 'AccountSettings',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AccountSettings',
        path: 'kontoinnstillinger/kontoinnstillinger',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__account_settings',
          routeDescription: 'menu__description__account_settings',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.ACCOUNT_SETTINGS]
        }
      }
    },
    {
      name: 'Privileges',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Privileges',
        path: 'kontrollpanel/brukeroversikt',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__privileges',
          routeDescription: 'menu__description__privileges',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.USER_MANAGEMENT]
        }
      }
    }
  ]
}

export default manifest
