import confetti from 'canvas-confetti'

const ROCKETS = confetti.shapeFromText({ text: '🚀', scalar: 2 })
const STARS = confetti.shapeFromText({ text: '⭐️', scalar: 2 })
const A_SHAPE = confetti.shapeFromPath({
  path: 'M23 5 C16 5, 10 10, 10 18 C10 30, 25 35, 35 35 C50 35, 60 28, 60 20 C60 10, 50 5, 40 5 C35 5, 30 8, 28 10 C27 8, 25 6, 23 5 Z',
  matrix: new DOMMatrix([0.3, 0, 0, 0.3, 0, 0])
})

export const fireConfettiAvonovaLogo = async () => {
  const defaults = {
    scalar: 2,
    spread: 180,
    particleCount: 30,
    origin: { y: -0.1 },
    startVelocity: -35,
    ticks: 400
  }

  confetti({
    ...defaults,
    shapes: [ROCKETS, A_SHAPE, STARS],
    colors: ['#2EE6C7', '#002218'] // Avonova colors
  })

  confetti({
    ...defaults,
    particleCount: 50,
    shapes: ['circle', 'square'],
    colors: ['#ffffff', '#26DAB9']
  })
}

export const fireConfettiOnTheSides = () => {
  const end = Date.now() + 3 * 1000 // 3 seconds

  ;(function frame() {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 }
    })
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 }
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}
