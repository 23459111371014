// Custom events defined here
// https://docs.google.com/document/d/1b0p0w9eZNmyT0BElkOQN7LmHZX8krmfYZgHKPtI6u4U
export enum GtmEvent {
  INIT_DATA = 'init_data',
  CLICK = 'click',
  TASK_PROCESS = 'task_process',
  TASK_COMPLETE = 'task_complete',
  SESSION_TIMEOUT = 'session_timeout',
  SEARCH_BAR_INTERACTED = 'search_bar_interacted'
}

export type GtmUserType = 'ADMIN' | 'MANAGER' | 'SUPPORT' | 'NORMAL'

export interface GtmDataLayer {
  event: GtmEvent
  dlAccountId?: number
  userId?: number
  dlNaeringskode1?: string
  topLevelMenuName?: string
  language?: string
  locale?: string
  fullName?: string
  companyName?: string
  companyEmail?: string
  companyPhone?: string
  dlUserType?: GtmUserType
  dlAccountAdminTag?: string
  dlTestAccount?: boolean
  dlOrgNumber?: string
  dlAccountCountry?: string
  dlAccountNumUsersAllowed?: number
  dlOnboardingCompleteDate?: string | null
  dlAssist?: boolean
  dlEntireBody?: boolean
  dlAssistHealth?: boolean
  dlAssistHealthInsurance?: boolean
  siteVersion?: string
  productPackage?: string
}
