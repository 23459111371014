import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { SNACKBAR } from '@/shared/constants/ui'
import type { NotifyOptions } from '@hms-kontoret/amber.types'

type SnackbarMessage = {
  type: 'success' | 'error'
  message: string
  duration?: number
  timestamp?: number
}

export const useUi = defineStore('ui', () => {
  // Layout State
  const isTopbarVisible = ref(true)
  const isSidebarVisible = ref(true)
  const isBannerExpanded = ref(false)

  const isFullscreen = computed((): boolean => {
    return !isTopbarVisible.value && !isSidebarVisible.value
  })

  const enterFullscreen = () => {
    isTopbarVisible.value = false
    isSidebarVisible.value = false
  }

  const exitFullscreen = () => {
    isTopbarVisible.value = true
    isSidebarVisible.value = true
  }

  const setBannerExpanded = (expanded: boolean) => {
    isBannerExpanded.value = expanded
  }

  // Snackbar State
  const snackbarQueue = ref<SnackbarMessage[]>([])
  const currentSnackbar = ref<SnackbarMessage | null>(null)
  const isSnackbarVisible = ref(false)

  const notify = (
    message: string,
    { duration = SNACKBAR.DURATION, type = 'success' }: NotifyOptions = {}
  ) => {
    const timestamp = Date.now()

    if (
      currentSnackbar.value?.message === message &&
      timestamp - (currentSnackbar.value?.timestamp || 0) <
        SNACKBAR.DUPLICATE_INTERVAL
    ) {
      return
    }

    snackbarQueue.value.push({ message, duration, timestamp, type })

    if (!isSnackbarVisible.value) {
      displayNextSnackbar()
    }
  }

  const displayNextSnackbar = () => {
    const next = snackbarQueue.value.shift()

    if (!next) return

    currentSnackbar.value = next
    isSnackbarVisible.value = true

    setTimeout(() => {
      isSnackbarVisible.value = false
      setTimeout(() => {
        displayNextSnackbar()
      }, 300)
    }, next.duration)
  }

  return {
    // Layout
    isTopbarVisible,
    isSidebarVisible,
    isBannerExpanded,

    // Getter

    isFullscreen,
    enterFullscreen,
    exitFullscreen,
    setBannerExpanded,

    // Snackbar
    isSnackbarVisible,
    currentSnackbar,
    notify
  }
})
