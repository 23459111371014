import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'

export const sentryPlugin = {
  install: (app: App, router: Router) => {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration({ router })],
      tracePropagationTargets: [
        'localhost',
        import.meta.env.VITE_SENTRY_PROPAGATION_TARGETS
      ],
      environment: import.meta.env.VITE_APP_ENVIRONMENT,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // Adjust this value in production as needed.
      tracesSampleRate: 1.0
    })
  }
}
