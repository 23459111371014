import {
  LDClient,
  LDContext,
  LDFlagChangeset,
  initialize as _initialize
} from 'launchdarkly-js-client-sdk'
import { Amber } from '@hms-kontoret/amber.types/Commands'

let client: LDClient | null = null

/**
 * Dispatch a custom event for feature flag updates
 */
const dispatchUpdateEvent = (add: string[], remove: string[]) => {
  window.dispatchEvent(
    new CustomEvent(Amber.Command.V1.FeatureFlag.Update.toString(), {
      detail: { add, remove }
    })
  )
}

/**
 * Handler for when the LaunchDarkly client is ready
 */
const onReady = () => {
  if (!client) {
    console.error('LaunchDarkly client is not initialized')
    return
  }

  const flags = client.allFlags()
  const activeFlags = Object.keys(flags).filter(key => flags[key])
  dispatchUpdateEvent(activeFlags, [])
}

/**
 * Handler for when the LaunchDarkly flags change
 */
const onChange = (changeSet: LDFlagChangeset) => {
  const toAdd: string[] = []
  const toRemove: string[] = []

  for (const [key, value] of Object.entries(changeSet)) {
    if (value.current && !value.previous) toAdd.push(key)
    else if (!value.current && value.previous) toRemove.push(key)
  }

  if (toAdd.length || toRemove.length) dispatchUpdateEvent(toAdd, toRemove)
}

export const initialize = (clientSideId: string): void => {
  client = _initialize(clientSideId, createContext())

  client.on('ready', onReady)
  client.on('change', onChange)
}

/**
 * Creates an Launch Darkly context
 */
export const createContext = (
  orgNr: string | null = null,
  accountId: number | null = null,
  userId: number | null = null,
  country: string | null = null
): LDContext => {
  return {
    kind: 'user',
    key: 'AvonovaDigital',
    orgNr: orgNr || null,
    accountId: accountId || null,
    userId: userId || null,
    country: country || null
  }
}

/**
 * Updates the LaunchDarkly context
 */
export const updateContext = async (context: LDContext): Promise<void> => {
  if (!client) {
    console.error('LaunchDarkly client is not initialized')
    return
  }

  try {
    await client.identify(context)
  } catch (error) {
    console.error('Failed to update LaunchDarkly context:', error)
    throw error
  }
}

/**
 * Hook to access the LaunchDarkly client
 * @returns The LaunchDarkly client instance
 */
export const useLaunchDarkly = (): LDClient | null => client

export default {
  async install() {
    const clientSideId: string = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID

    if (!clientSideId) throw new Error('LaunchDarkly client ID is missing.')

    initialize(clientSideId)
  }
}
