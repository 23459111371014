import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'MyPage',
  route: {
    name: 'MyPage',
    path: '/employee',
    alias: ['/personnel'],
    meta: {
      routeTitle: 'menus__title__employee',
      menuIcon: 'n-icon-user-c-frame-2',
      isVisibleInNavMenu: true
    }
  },
  children: [
    {
      name: 'EmployeeMenuLandingPage',
      remote: {
        module: 'menus'
      },
      route: {
        name: 'EmployeeMenuLandingPage',
        path: '',
        meta: {
          routeTitle: 'menus__landingpage__title__employee',
          routeDescription: 'menu__description__employee'
        }
      }
    },
    {
      name: 'MyVacation',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyVacation',
        path: 'vacation',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__my_vacation',
          routeDescription: 'menu__description__my_vacation',
          menuIcon: 'n-icon-passenger-2',
          requiresAccessRights: [MENU_ACCESS.MY_VACATION]
        }
      }
    },
    {
      name: 'MyAbsence',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyAbsence',
        path: 'absence',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__my_absence',
          routeDescription: 'menu__description__my_absence',
          menuIcon: 'n-icon-coughing-2',
          requiresAccessRights: [MENU_ACCESS.MY_ABSENCE]
        }
      }
    },
    {
      name: 'MyCompetence',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyCompetence',
        path: 'competence',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__my_competence',
          routeDescription: 'menu__description__my_competence',
          menuIcon: 'n-icon-presentation-2',
          requiresAccessRights: [MENU_ACCESS.MY_COMPETENCE]
        }
      }
    },
    {
      name: 'MyResponse',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyResponse',
        path: 'response',
        alias: ['my-response/'],
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__my_respons',
          routeDescription: 'menu__description__my_respons',
          menuIcon: 'n-icon-like',
          requiresAccessRights: [MENU_ACCESS.MY_RESPONSE]
        }
      }
    },
    {
      name: 'MyHours',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyHours',
        path: 'hours',
        alias: ['my-hours'],
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__my_hours',
          routeDescription: 'menu__description__my_hours',
          menuIcon: 'n-icon-time-clock',
          requiresAccessRights: [MENU_ACCESS.MY_HOURS]
        }
      }
    },
    {
      name: 'MyIncidents',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyIncidents',
        path: 'incidents',
        alias: ['my-incidents'],
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__my_incidents',
          routeDescription: 'menu__description__my_incidents',
          menuIcon: 'n-icon-feedback-2',
          requiresAccessRights: [MENU_ACCESS.MY_INCIDENTS]
        }
      }
    },
    {
      name: 'MyJobSafetyAnalysis',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyJobSafetyAnalysis',
        path: 'risikosenter/jsa/my_jobsafetyanalysis_overview',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__my_job_safety_analysis',
          routeDescription: 'menu__description__my_job_safety_analysis',
          menuIcon: 'n-icon-construction-sign-2',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.MY_JOB_SAFETY_ANALYSIS]
        }
      }
    },
    {
      name: 'MyHmsHandbook',
      remote: {
        module: 'doc-vault'
      },
      route: {
        name: 'MyHmsHandbook',
        path: 'hms-handbook',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menus__title__employee_hms_handbook',
          routeDescription: 'menus__description__employee_hms_handbook',
          menuIcon: 'n-icon-book-39-2',
          requiresAccessRights: [MENU_ACCESS.HANDBOOK_DIRECTIVE]
        }
      }
    },
    {
      name: 'MyInstructions',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyInstructions',
        path: 'styrendedokumenter/styrendeDokumenter_groupedbycategory',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__my_instructions',
          routeDescription: 'menu__description__my_instructions',
          menuIcon: 'n-icon-collection',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.READ_DIRECTIVES]
        }
      }
    },
    {
      name: 'MyInformation',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyInformation',
        path: 'ansatte/minside/minSide',
        meta: {
          isVisibleInNavMenu: false,
          routeTitle: 'menus__profile__my_information',
          menuIcon: 'n-icon-user',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.CUSTOM_MENU_ACCESS__MY_INFORMATION]
        }
      }
    }
  ]
}

export default manifest
