import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'Directives',
  route: {
    name: 'Directives',
    path: '/directives',
    meta: {
      routeTitle: 'menus__title__directives',
      menuIcon: 'n-icon-book-39-2',
      isVisibleInNavMenu: true
    }
  },
  children: [
    {
      name: 'DirectivesMenuLandingPage',
      remote: {
        module: 'menus'
      },
      route: {
        name: 'DirectivesMenuLandingPage',
        path: '',
        meta: {
          routeTitle: 'menus__landingpage__title__directives',
          routeDescription: 'menu__description__directives'
        }
      }
    },
    {
      name: 'Instructions',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Instructions',
        path: 'styrendedokumenter/styrendeDokumenter',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__instructions',
          routeDescription: 'menu__description__instructions',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.GOVERNANCE_DIRECTIVE],
          menuIcon: 'n-icon-collection'
        }
      }
    },
    {
      name: 'Handbooks',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Handbooks',
        path: 'handbok/handbok',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__handbooks',
          routeDescription: 'menu__description__handbooks',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.HANDBOOK_DIRECTIVE],
          menuIcon: 'n-icon-book-39-2'
        }
      }
    }
  ]
}

export default manifest
