import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'Employer',
  route: {
    name: 'Employer',
    path: '/employer',
    alias: ['/personnel'],
    meta: {
      routeTitle: 'menus__title__personnel',
      menuIcon: 'n-icon-users-mm-3',
      isVisibleInNavMenu: true
    }
  },
  children: [
    {
      name: 'EmployerMenuLandingPage',
      remote: {
        module: 'menus'
      },
      route: {
        name: 'EmployerMenuLandingPage',
        path: '',
        meta: {
          routeTitle: 'menus__landingpage__title__personnel',
          routeDescription: 'menu__description__personnel'
        }
      }
    },
    {
      name: 'EmployeesOverview',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'EmployeesOverview',
        path: 'ansatte/ansatte',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__employees',
          routeDescription: 'menu__description__employees',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_EMPLOYEES],
          menuIcon: 'n-icon-users-mm-3'
        }
      }
    },
    {
      name: 'EmployeeForms',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'EmployeeForms',
        path: 'employee_forms/employee_forms',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__employee_forms',
          routeDescription: 'menu__description__employee_forms',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.EMPLOYEE_FORMS],
          menuIcon: 'n-icon-paper-2'
        }
      }
    },
    {
      name: 'WorkSchedules',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'WorkSchedules',
        path: 'arbeidsplan/arbeidsplan',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__work_schedules',
          routeDescription: 'menu__description__work_schedules',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.WORK_SCHEDULE],
          menuIcon: 'n-icon-opening-times-2'
        }
      }
    },
    {
      name: 'Vacation',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Vacation',
        path: 'vacation',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__vacation',
          routeDescription: 'menu__description__vacation',
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_VACATION],
          menuIcon: 'n-icon-passenger-2'
        }
      }
    },
    {
      name: 'Absence',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Absence',
        path: 'absence',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__absence',
          routeDescription: 'menu__description__absence',
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_ABSENCE],
          menuIcon: 'n-icon-coughing-2'
        }
      }
    },
    {
      name: 'Competence',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Competence',
        path: 'competence',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__competence',
          routeDescription: 'menu__description__competence',
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_COMPETENCE],
          menuIcon: 'n-icon-presentation-2'
        }
      }
    },
    {
      name: 'EmployeeInterviews',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'EmployeeInterviews',
        path: 'employee_interview/employee_interview',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__employee_interviews',
          routeDescription: 'menu__description__employee_interviews',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.EMPLOYEE_INTERVIEW],
          menuIcon: 'n-icon-users-chat'
        }
      }
    },
    {
      name: 'Response',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Response',
        path: 'response',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__response',
          routeDescription: 'menu__description__response',
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_SURVEY],
          menuIcon: 'n-icon-like'
        }
      }
    },
    {
      name: 'ResponseTemplate',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'ResponseTemplate',
        path: 'response/template',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__response_template',
          routeDescription: 'menu__description__response_template',
          requiresAccessRights: [MENU_ACCESS.RESPONSE_TEMPLATE],
          menuIcon: 'n-icon-like'
        }
      }
    },
    {
      name: 'Hours',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Hours',
        path: 'timeregistrering/timeregistrering',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__hours',
          routeDescription: 'menu__description__hours',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.TIME_REGISTRATION],
          menuIcon: 'n-icon-time-clock'
        }
      }
    }
  ]
}

export default manifest
