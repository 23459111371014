import { MENU_ACCESS } from '@/shared/constants/menuAccess'
import type { RouteManifest } from '@hms-kontoret/amber.types'

const manifest: RouteManifest = {
  name: 'IncidentRegistry',
  route: {
    name: 'IncidentRegistry',
    path: '/incident-registry',
    alias: ['/incidentregistry'],
    meta: {
      isVisibleInNavMenu: true,
      routeTitle: 'menus__title__incident_registry',
      menuIcon: 'n-icon-check-list-3'
    }
  },
  children: [
    {
      name: 'IncidentRegistryMenuLandingPage',
      remote: {
        module: 'menus'
      },
      route: {
        name: 'IncidentRegistryMenuLandingPage',
        path: '',
        meta: {
          routeTitle: 'menus__landingpage__title__incident_registry',
          routeDescription: 'menu__description__incident_registry'
        }
      }
    },
    {
      name: 'ActionPlan',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'ActionPlan',
        path: 'action-plan',
        alias: ['actionplan'],
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__action_plan',
          routeDescription: 'menu__description__action_plan',
          requiresAccessRights: [MENU_ACCESS.EDIT_MEASURE],
          menuIcon: 'n-icon-recipe'
        }
      }
    },
    {
      name: 'SafetyInspections',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'SafetyInspections',
        path: 'safety-inspections',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__safety_inspections',
          routeDescription: 'menu__description__safety_inspections',
          requiresAccessRights: [MENU_ACCESS.SAFETY_INSPECTIONS_EDIT],
          menuIcon: 'n-icon-check-list-3'
        }
      }
    },
    {
      name: 'Incident',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Incident',
        path: 'incidents',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__incidents',
          routeDescription: 'menu__description__incidents',
          requiresAccessRights: [MENU_ACCESS.EDIT_INCIDENT],
          menuIcon: 'n-icon-feedback-2'
        }
      }
    },
    {
      name: 'Inspections',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Inspections',
        path: 'inspections',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__inspections',
          routeDescription: 'menu__description__inspections',
          requiresAccessRights: [MENU_ACCESS.EDIT_INSPECTION_REPORT],
          menuIcon: 'n-icon-house-search'
        }
      }
    },
    {
      name: 'AuditReports',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AuditReports',
        path: 'hendelsesregister/revisjon/revisions',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__audit_reports',
          routeDescription: 'menu__description__audit_reports',
          requiresAccessRights: [MENU_ACCESS.EDIT_AUDIT_REPORT],
          isStandaloneRoute: true,
          menuIcon: 'n-icon-research-2'
        }
      }
    },
    {
      name: 'Reports',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Reports',
        path: 'hendelsesregister/rapporter/oversikt',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__reports',
          routeDescription: 'menu__description__reports',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.REPORTS],
          menuIcon: 'n-icon-chart-bar-33-2'
        }
      }
    },
    {
      name: 'GroupReports',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'GroupReports',
        path: 'konsern/konsernrapporter',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__group_reports',
          routeDescription: 'menu__description__group_reports',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.CORPORATE_REPORT],
          menuIcon: 'n-icon-chart-bar-33-2'
        }
      }
    }
  ]
}

export default manifest
