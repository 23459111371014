<template>
  <Transition name="snackbar-fade">
    <avo-snackbar v-if="isSnackbarVisible && currentSnackbar">
      <avo-snackbar-content
        :type="currentSnackbar.type"
        :text="currentSnackbar.message"
      />
    </avo-snackbar>
  </Transition>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useUi } from '@/stores/ui'

const ui = useUi()

const isSnackbarVisible = computed(() => ui.isSnackbarVisible)
const currentSnackbar = computed(() => ui.currentSnackbar)
</script>

<style scoped lang="scss">
.snackbar-fade-enter-active,
.snackbar-fade-leave-active {
  transition: all 0.3s ease;
}
.snackbar-fade-enter-from,
.snackbar-fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

avo-snackbar {
  position: fixed;
  z-index: 50;
  padding: 1rem;
  border-radius: 0.5rem;
  color: white;

  @media (max-width: $mobile) {
    top: unset;
    bottom: 2.5rem;
    left: 0;
    right: 0;
  }

  @media (min-width: $tablet) {
    bottom: 2.5rem;
    left: 14rem;
  }
}
</style>
