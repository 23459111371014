<template>
  <div v-if="isBannerExpanded" class="banner">
    <div class="support-banner">
      <avo-icon class="icon" icon="n-icon-user" />
      {{ $t('topbar__support_user') + ' - ' + companyName }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAuthUser } from '../stores/authUser/index'
import { useUi } from '@/stores/ui'

const { companyName } = useAuthUser()
const uiStore = useUi()

const isBannerExpanded = computed(() => {
  return uiStore.isBannerExpanded
})
</script>

<style scoped lang="scss">
.banner {
  background-color: $brand-primary-fill;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-4xs;
  color: white;
  min-height: 50px;
  transition:
    min-height 0.3s ease,
    opacity 0.3s ease;
  &.hidden {
    min-height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

.support-banner {
  display: flex;
  align-items: center;
  .icon {
    margin-right: $spacing-4xs;
    margin-bottom: 2px;
  }
}
</style>
