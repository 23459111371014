export namespace Amber.Command {
  export namespace V1 {
    export namespace RouteContext {
      export namespace Route {
        /**
         * Command to navigate to a route.
         * @event amber.command.v1.routecontext.route.navigateto
         * @property {string} path - The path to navigate to. Example: '/simple-example/sample'
         */
        export class NavigateTo {
          path: string

          constructor(path: string) {
            this.path = path
          }

          /**
           * Returns the command name as a string.
           * @returns {string} The command name.
           */
          static toString(): string {
            return 'amber.command.v1.routecontext.route.navigateto'
          }
        }

        /**
         * Command to replace the current state with a new state.
         * @event amber.command.v1.routecontext.replacestate
         * @property {string} path - The path to replace the current state with. Example: '/simple-example/sample'
         */
        export class ReplaceState {
          path: string

          constructor(path: string) {
            this.path = path
          }

          /**
           * Returns the command name as a string.
           * @returns {string} The command name.
           */
          static toString(): string {
            return 'amber.command.v1.routecontext.route.replacestate'
          }
        }
      }
    }

    export namespace FeatureFlag {
      /**
       * Command to update feature flags by adding and/or removing them.
       * @event amber.command.v1.featureflag.update
       * @property {string[]} add - Feature flags to be added/enabled.
       * @property {string[]} remove - Feature flags to be removed/disabled.
       */
      export class Update {
        add: string[]
        remove: string[]

        constructor(params: { add?: string[]; remove?: string[] }) {
          this.add = params.add ?? []
          this.remove = params.remove ?? []
        }

        /**
         * Returns the command name as a string.
         * @returns {string}
         */
        static toString(): string {
          return 'amber.command.v1.featureflag.update'
        }
      }
    }
  }
}
