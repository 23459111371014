import type { UserState, CompanyState, AccountState } from './StoreStates'
import type { RouteManifest, MFEManifest } from './RouteManifest'
import { type GtmDataLayer, GtmEvent } from './Gtm'
import { BeforeLeaveGuard } from './RouteGuards'
import { RouteLocationNormalizedLoadedGeneric } from 'vue-router'
import { NotifyOptions } from './UiStore'

export namespace Amber.API {
  export namespace V1 {
    /**
     * Represents the root of the Amber API with versioning.
     */
    export interface Contexts {
      authContext: AuthContext
      featureFlagContext: FeatureFlagContext
      routerContext: RouterContext
      i18nContext: I18nContext
      analyticsContext: AnalyticsContext
      uiContext: UiContext
      funContext: FunContext
    }

    /**
     * Represents the authentication token containing both the access and ID tokens.
     * @property {string} accessToken - The access token used for API requests.
     * @property {string} idToken - The ID token used for authentication.
     */
    export interface AuthToken {
      accessToken: string
      idToken: string
    }

    /**
     * Represents the authentication context, providing methods to interact with
     * the authenticated user's data, access rights, and session management.
     */
    export interface AuthContext {
      /**
       * Retrieves the authenticated user's information.
       * @returns {UserState} An object containing the user's profile information.
       */
      getAuthUser: () => UserState

      /**
       * Retrieves the authenticated user's associated company information.
       * @returns {CompanyState} An object containing the company name, contact information, and organization details.
       */
      getAuthUserCompany: () => CompanyState

      /**
       * Retrieves the authenticated user's account information.
       * @returns {AccountState} An object containing the user's account ID, country, and supported locales.
       */
      getAuthUserAccount: () => AccountState

      /**
       * Retrieves a list of access items (permissions or roles) for the authenticated user.
       * @returns {Array<string>} A list of access items representing the user's permissions.
       */
      getAuthUserAccessItems: () => Array<string>

      /**
       * Retrieves the authentication token for the current session.
       * @returns {Promise<AuthToken>} A promise that resolves to an object containing the access and ID tokens.
       */
      getToken: () => Promise<AuthToken>

      /**
       * NB: Should not be used if not necessary because it might not be up-to-date.
       * Retrieves the authentication token from the MSAL cache.
       * @returns {AuthToken | null}
       * The access and ID tokens if found in the cache, otherwise null.
       */
      getTokenFromCache: () => AuthToken | null

      /**
       * Checks if a specific menu item is accessible by the authenticated user.
       * @param {string} menuItem - The menu item to check for access rights.
       * @returns {boolean} True if the menu item is accessible, otherwise false.
       */
      isMenuItemAccessible: (menuItem: string) => boolean

      /**
       * Checks if any of the provided menu items are accessible by the authenticated user.
       * @param {Array<string>} menuItems - A list of menu items to check for access rights.
       * @returns {boolean} True if at least one menu item is accessible, otherwise false.
       */
      isAnyMenuItemAccessible: (menuItems: string[]) => boolean

      /**
       * Logs out the authenticated user, ending their session.
       * Typically clears any session data or tokens related to the user's authentication.
       */
      logout: () => void
    }

    /**
     * Represents the feature flag context, allowing toggling of app features based on feature flags.
     */
    export interface FeatureFlagContext {
      /**
       * Retrieves all active feature flags.
       * @returns {string[]} Returns an array of active feature flags.
       */
      getFlags: () => string[]

      /**
       * Checks if a specific feature is enabled.
       * @param {string} feature - The feature flag to check.
       * @returns {boolean} True if the feature is enabled, otherwise false.
       */
      isFeatureEnabled: (feature: string) => boolean

      /**
       * Checks if any of the provided features are enabled.
       * @param {Array<string>} features - A list of feature flags to check.
       * @returns {boolean} True if at least one feature is enabled, otherwise false.
       */
      isAnyFeatureEnabled: (features: string[]) => boolean
    }

    /**
     * Represents the router context, allowing navigation within the application and access to route manifests.
     */
    export interface RouterContext {
      /**
       * Navigates to a specific path within the application.
       * @param {string} path - The path to navigate to.
       */
      navigateTo: (path: string) => void

      /**
       * Replaces the current state with a new path in the application's history.
       * @param {string} path - The path to replace the current state with.
       */
      replaceState: (path: string) => void

      /**
       * Retrieves the current route.
       * @returns {T} The current route information.
       */
      getCurrentRoute: () => RouteLocationNormalizedLoadedGeneric | null

      /**
       * Retrieves the manifests of all available routes.
       * @returns {RouteManifest[]} An array of route manifests.
       */
      getRouteManifests: () => RouteManifest[]

      /**
       * Retrieves the manifest of the current route.
       * @returns {MFEManifest}
       */
      getCurrentManifest: () => MFEManifest | null

      /**
       * Retrieves the base path of the application.
       * E.g: digital.avonova.no/
       * @returns {string} The base path.
       */
      getBasePath: () => string

      /**
       * Retrieves the relative base path of the application.
       * E.g: /
       * @returns {string} The relative base path.
       */
      getRelativeBasePath: () => string

      /**
       * Registers before route change guard.
       * @param {string} path - The path to guard.
       * @param {BeforeLeaveGuard} callback - The callback to execute
       */
      registerBeforeLeaveGuard: (
        path: string,
        callback: BeforeLeaveGuard
      ) => void

      /**
       * Unregisters before route change guard.
       * @param {string} path - The path to unguard.
       */
      unregisterBeforeLeaveGuard: (path: string) => void
    }

    /**
     * Represents the internationalization (i18n) context, allowing for translation and locale management.
     */
    export interface I18nContext {
      /**
       * Translates a given key into the current language.
       * @param {string} key - The translation key.
       * @param {any} [params] - Optional parameters to be included in the translation.
       * @returns {string} The translated string.
       */
      translate: (key: string, params?: any) => string

      /**
       * Provides a pluralized version of a translation based on a choice number.
       * @param {string} key - The translation key.
       * @param {number} choice - The choice number to determine the correct plural form.
       * @returns {string} The pluralized translation.
       */
      pluralize: (key: string, choice: number) => string

      /**
       * Updates the current locale of the application.
       * @param {string} locale - The new locale to switch to.
       * @returns {Promise<void>} A promise that resolves when the locale is updated.
       */
      updateLocale: (locale: string) => Promise<void>

      /**
       * Retrieves the current locale of the i18n. Should not use this for other purposes than with Contentful. Use AuthContext for user locale.
       * @returns {string} The current locale.
       */
      getLocale: () => string
    }

    /**
     * Analytics context for tracking events and updating the data layer.
     */
    export interface AnalyticsContext {
      /**
       * Tracks a page view event.
       * @param {GtmEvent} eventName - The name of the event to track. See GtmEvent for available events.
       * @param {string} eventTriggerId - The unique identifier of the event trigger.
       * @returns {void}
       */
      trackEvent: (eventName: GtmEvent, eventTriggerId: string) => void

      /**
       * Updates the data layer with new information.
       * @param {GtmDataLayer} dataLayer - The updated data layer object.
       * @returns {void}
       */
      updateDataLayer: (dataLayer: GtmDataLayer) => void
    }

    /**
     * Represents the UI context for managing fullscreen and related states.
     */
    export interface UiContext {
      /**
       * Enters fullscreen mode. Hide top and side navigation bars.
       * @returns {void}
       */
      enterFullscreen: () => void

      /**
       * Exits fullscreen mode. Show top and side navigation bars.
       * @returns {void}
       */
      exitFullscreen: () => void

      /**
       * Toggles fullscreen mode.
       * @returns {boolean}
       */
      isFullscreen: () => boolean

      /**
       * Displays a snackbar notification to the user.
       * Automatically queues the message if another is already visible.
       * Duplicate messages shown within a short interval are ignored.
       *
       * @param {string} message - The message to display.
       * @param {Object} [options] - Optional configuration.
       * @param {number} [options.duration] - Duration to show the snackbar in milliseconds. Default is 6000.
       * @param {'success' | 'error'} [options.type] - Type of the snackbar. Default is 'success'.
       * @returns {void}
       */
      notify: (message: string, options?: NotifyOptions) => void
    }

    /**
     * Represents the fun context for triggering fun effects.
     */
    export interface FunContext {
      /**
       * Fires confetti on the screen.
       * @returns {void}
       */
      fireConfetti: () => void
    }
  }
}
