import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'Manuals',
  route: {
    name: 'Manuals',
    path: '/manuals',
    meta: {
      routeTitle: 'menus__title__manuals',
      menuIcon: 'n-icon-book-39-2',
      isVisibleInNavMenu: true
    }
  },
  children: [
    {
      name: 'ManualsMenuLandingPage',
      remote: {
        module: 'menus'
      },
      route: {
        name: 'ManualsMenuLandingPage',
        path: '',
        meta: {
          routeTitle: 'menus__landingpage__title__manuals',
          routeDescription: 'menu__description__manuals'
        }
      }
    },
    {
      name: 'HandbooksRead',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'HandbooksRead',
        path: 'personalhandbok/handbooksread',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__handbooks_read',
          routeDescription: 'menu__description__handbooks_read',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.HANDBOOK_READ],
          menuIcon: 'n-icon-book-39-2'
        }
      }
    },
    {
      name: 'AdministrateHandbooks',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AdministrateHandbooks',
        path: 'personalhandbok/personalhandbokadmin',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__administrate_handbooks',
          routeDescription: 'menu__description__administrate_handbooks',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.HANDBOOK_ADMIN],
          menuIcon: 'n-icon-book-39-2'
        }
      }
    },
    {
      name: 'SettingsForManuals',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'SettingsForManuals',
        path: 'personalhandbok/personalhandbokadmin_innstillinger',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__settings_for_manuals',
          routeDescription: 'menu__description__settings_for_manuals',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.HANDBOOK_SETTINGS],
          menuIcon: 'n-icon-book-39-2'
        }
      }
    }
  ]
}

export default manifest
