import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAuthenticationStore = defineStore('authentication', () => {
  const monolithLastLoginCall = ref<number | null>(null)
  const monolithLastKeepAliveCall = ref<number | null>(null)
  const monolithKeepAliveInterval = ref<NodeJS.Timeout | null>(null)

  // MSAL token retrieval
  const lastMsalTokenRetrieval = ref<number | null>(null)

  const setMonolithLastLoginCall = (timestamp: number | null) => {
    monolithLastLoginCall.value = timestamp
  }

  const setMonolithLastKeepAliveCall = (timestamp: number | null) => {
    monolithLastKeepAliveCall.value = timestamp
  }

  const setMonolithKeepAliveInterval = (interval: NodeJS.Timeout | null) => {
    monolithKeepAliveInterval.value = interval
  }

  const setLastMsalTokenRetrieval = (timestamp: number | null) => {
    lastMsalTokenRetrieval.value = timestamp
  }

  const getMonolithKeepAliveInterval = () => monolithKeepAliveInterval.value

  const clearMonolithKeepAliveInterval = () => {
    if (monolithKeepAliveInterval.value) {
      clearInterval(monolithKeepAliveInterval.value)
      monolithKeepAliveInterval.value = null
    }
  }

  const getMonolithLastLoginCall = () => monolithLastLoginCall.value

  const getMonolithLastKeepAliveCall = () => monolithLastKeepAliveCall.value

  const getLastMsalTokenRetrieval = () => lastMsalTokenRetrieval.value

  return {
    setLastMsalTokenRetrieval,
    getLastMsalTokenRetrieval,

    setMonolithLastLoginCall,
    getMonolithLastLoginCall,

    setMonolithLastKeepAliveCall,
    getMonolithLastKeepAliveCall,
    setMonolithKeepAliveInterval,
    getMonolithKeepAliveInterval,
    clearMonolithKeepAliveInterval
  }
})
