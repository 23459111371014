import type { App } from 'vue'
import { init, loadRemote as _loadRemote } from '@module-federation/runtime'
import { defineAsyncComponent } from 'vue'
import { RemoteModule } from '@/shared/types'
import { Remote } from '@hms-kontoret/amber.types'

/**
 * List of static remotes that are loaded and registered on application startup.
 */
export const staticRemotes: Remote[] = [
  {
    module: 'menus',
    components: ['SidebarMenu', 'HamburgerSidebarMenu', 'TopBar']
  }
]

/**
 * Get the entry point URL for a remote module.
 * @param module - The name of the module.
 * @returns The URL of the remote entry point.
 */
export const getEntry = (module: string) => {
  // Uncomment the following line to use localhost for development
  // if (module === 'iframefusion')
  //   return 'http://localhost:5012/remoteEntry.js'

  // Generate a unique timestamp every 10 minutes
  const timestamp = Math.floor(Date.now() / (1000 * 60 * 10)) // Changes every 10 minutes

  return `${import.meta.env.VITE_APP_REMOTE_BASE_URL}/${module}/remoteEntry.js?timestamp=${timestamp}`
}

/**
 * Load and register static remotes.
 * @param app - The Vue application instance.
 */
const loadAndRegisterStaticRemotes = async (app: App) => {
  await Promise.all(
    staticRemotes.map(async (remote: Remote) => {
      if (!remote.components?.length) return

      await Promise.all(
        remote.components.map(async component => {
          try {
            const asyncComponent = defineAsyncComponent(async () => {
              const module = await _loadRemote(`${remote.module}/${component}`)

              if (!module || !(module as any)?.default) {
                throw new Error(
                  `Module ${component} from ${remote.module} is invalid or missing default export`
                )
              }

              return (module as { default: any }).default // Explicitly cast to ensure it's a valid Vue component
            })
            app.component(component, asyncComponent)
          } catch (error) {
            console.error(
              `Failed to load remote component ${component} from ${remote.module}:`,
              error
            )
          }
        })
      )
    })
  )
}

/**
 * Plugin for initializing Module Federation and loading/registering static remotes.
 */
export const federationPlugin = {
  /**
   * Install the federation plugin.
   * @param app - The Vue application instance.
   * @param remotes - The list of dynamic remotes to be merged with static remotes.
   */
  install: (app: App, remotes: Remote[]) => {
    // Merge static and dynamic remotes
    remotes = [...staticRemotes, ...remotes]

    const mfRemotes = remotes.map((remote: Remote) => ({
      name: remote.module,
      entry: remote.entry ?? getEntry(remote.module),
      type: 'module'
    }))

    // Initialize Module Federation Runtime
    init({
      name: import.meta.env.VITE_APP_NAME as string,
      remotes: mfRemotes
    })

    // Load and register static remotes
    loadAndRegisterStaticRemotes(app)
  }
}

export default federationPlugin

/**
 * Hook to use Module Federation.
 */
export const useModuleFederation = () => {
  /**
   * Loads a remote module asynchronously.
   *
   * @param module - The name of the module to load.
   * @returns A promise that resolves to a `RemoteModule` object containing the module's name, mount, and unmount functions.
   */
  const loadRemote = async (module: string): Promise<RemoteModule> => {
    const loadedModule = (await _loadRemote(`${module}/entry`)) as RemoteModule
    return {
      name: module,
      mount: loadedModule.mount,
      unmount: loadedModule.unmount
    } as RemoteModule
  }

  return { loadRemote }
}
