import { useMonolithService } from '@/services/monolithService'
import { useAuthenticationStore } from '@/stores/authentication'
import { formatDuration } from '@/shared/utils/dateUtils'

// Enum for monolith service operations
export enum MonolithOperationType {
  LOGIN = 'login',
  LOGOUT = 'logout',
  KEEP_ALIVE = 'keepAlive'
}

export const KEEP_ALIVE_INTERVAL_MS = 600000 // 10 minute
export const LOGIN_INTERVAL_MS = 60000 // 1 minute

export const shouldSkipKeepAlive = (): boolean => {
  return shouldSkipOperation(
    'keep-alive',
    useAuthenticationStore().getMonolithLastKeepAliveCall(),
    KEEP_ALIVE_INTERVAL_MS
  )
}

export const shouldSkipLogin = (): boolean => {
  return shouldSkipOperation(
    'login',
    useAuthenticationStore().getMonolithLastLoginCall(),
    LOGIN_INTERVAL_MS
  )
}

/**
 * Generic function to check if an operation should be skipped.
 */
const shouldSkipOperation = (
  operation: string,
  lastCall: number | null,
  interval: number
): boolean => {
  console.log('Amber:MonolithOperation:shouldSkip', lastCall)
  // First call should never be skipped
  if (lastCall === null) return false

  const timeSinceLastCall = Date.now() - lastCall
  if (timeSinceLastCall >= interval) return false

  console.info(
    `Amber:MonolithOperation:shouldSkip:${operation}`,
    `Skipping ${operation}: Last call was ${formatDuration(timeSinceLastCall)} ago (at ${new Date(lastCall).toLocaleString()}), within the ${formatDuration(interval)} interval.`
  )

  return true
}

export const registerKeepMonolithAliveInterval = async () => {
  const {
    setMonolithLastKeepAliveCall,
    clearMonolithKeepAliveInterval,
    setMonolithKeepAliveInterval
  } = useAuthenticationStore()

  // Clear any existing keep-alive interval before registering a new one
  clearMonolithKeepAliveInterval()

  await handleMonolithAuth(MonolithOperationType.KEEP_ALIVE)
  setMonolithLastKeepAliveCall(Date.now())

  const keepAliveInterval = setInterval(async () => {
    if (shouldSkipKeepAlive()) return

    try {
      await handleMonolithAuth(MonolithOperationType.KEEP_ALIVE)
      setMonolithLastKeepAliveCall(Date.now())
    } catch (error) {
      console.error('Error during monolith keep-alive:', error)
    }
  }, KEEP_ALIVE_INTERVAL_MS)

  setMonolithKeepAliveInterval(keepAliveInterval)
}

// Handles monolith authentication operations
export const handleMonolithAuth = async (
  action: MonolithOperationType,
  idToken?: string
) => {
  console.info('Amber:MonolithOperation:handleMonolithAuth', action)

  const monolithService = useMonolithService()
  const { clearMonolithKeepAliveInterval, setMonolithLastLoginCall } =
    useAuthenticationStore()

  switch (action) {
    case MonolithOperationType.LOGIN:
      if (!idToken) {
        console.error('No idToken provided for Monolith operation:', action)
        return
      }

      if (shouldSkipLogin()) return

      await monolithService.login(idToken)
      setMonolithLastLoginCall(Date.now())

      await registerKeepMonolithAliveInterval()
      break

    case MonolithOperationType.LOGOUT:
      await monolithService.logout()
      clearMonolithKeepAliveInterval()
      break

    case MonolithOperationType.KEEP_ALIVE:
      if (shouldSkipKeepAlive()) return
      await monolithService.keepAlive()
      break
  }
}
