import type {
  MFEManifest,
  Remote,
  RouteManifest
} from '@hms-kontoret/amber.types'
import IncidentRegistry from '@/manifest/routeManifests/incidentRegistry'
import Directives from '@/manifest/routeManifests/directives'
import MyCompany from '@/manifest/routeManifests/myCompany'
import Employer from '@/manifest/routeManifests/employer'
import RiskCenter from '@/manifest/routeManifests/riskCenter'
import CardFile from '@/manifest/routeManifests/cardFile'
import Settings from '@/manifest/routeManifests/settings'
import Manuals from '@/manifest/routeManifests/manuals'
import MyPage from '@/manifest/routeManifests/myPage'
import CustomerSupport from '@/manifest/routeManifests/customerSupport'
import SimpleExample from '@/manifest/routeManifests/simpleExample'
import Onboarding from '@/manifest/routeManifests/onboarding'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'
const manifest: Array<RouteManifest> = [
  MyPage,
  MyCompany,
  Employer,
  Directives,
  RiskCenter,
  IncidentRegistry,
  CardFile,
  Settings,
  Manuals,
  CustomerSupport,
  Onboarding
]

// Custom manifests for special routes that don't fit the standard structure
const customManifests: Array<RouteManifest> = [
  {
    name: 'StartPage',
    route: {
      name: 'StartPage',
      path: '/home',
      alias: ['/services'],
      meta: {
        routeTitle: 'menus__title__start_page',
        isVisibleInNavMenu: true,
        menuIcon: 'n-icon-home'
      }
    },
    children: [
      {
        name: 'StartPageMenuLandingPage',
        remote: {
          module: 'start-page'
        },
        route: {
          name: 'StartPageMenuLandingPage',
          path: '',
          meta: {
            routeDescription: 'menu__description__start_page'
          }
        }
      },
      {
        name: 'StartPageTaskList',
        remote: {
          module: 'iframefusion'
        },
        route: {
          name: 'StartPageTaskList',
          path: 'startside/tasksdashboardpage',
          meta: {
            isVisibleInNavMenu: false,
            routeTitle: 'menus__profile__task_list',
            menuIcon: 'n-icon-bookmark',
            isStandaloneRoute: true,
            requiresAccessRights: [MENU_ACCESS.CUSTOM_MENU_ACCESS__TASK_LIST]
          }
        }
      }
    ]
  }
]

// Fallback route for unknown routes
const monolithManifest: RouteManifest = {
  name: 'Monolith',
  route: {
    name: 'Monolith',
    path: '/',
    meta: {
      routeTitle: 'menu__title__monolith'
    }
  },
  children: [
    {
      name: 'Dashboard',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Dashboard',
        path: 'index',
        meta: {
          routeTitle: 'menu__title__dashboard'
        }
      }
    }
  ]
}

export const fallbackManifest: RouteManifest = {
  name: 'Fallback',
  route: {
    name: 'Fallback',
    path: '/:pathMatch(.*)*'
  },
  children: [
    {
      name: 'FallbackChild',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'FallbackChild',
        path: '',
        meta: {
          routeTitle: 'Avonova'
        }
      }
    }
  ]
}

const baseManifests: Array<RouteManifest> = [monolithManifest, fallbackManifest]

export const getRouteManifests = (): Array<RouteManifest> => {
  const isLocal = import.meta.env.VITE_APP_ENVIRONMENT === 'local'

  const combinedManifests = [...baseManifests, ...customManifests, ...manifest]
  return isLocal ? [...combinedManifests, SimpleExample] : combinedManifests
}

export const findRouteManifest = (
  parentName: string,
  childName: string
): MFEManifest | null => {
  const manifest = getRouteManifests()
  const parent = manifest.find(x => x.name === parentName)
  if (!parent) return null

  const child = parent.children.find(mfe => mfe.name === childName)
  if (!child) return null

  return child
}

/**
 * Retrieves all unique remotes from the route manifests.
 * @returns {Array<Remote>} Array of unique `Remote` objects.
 */
export const getRemotes = (): Array<Remote> => {
  const manifests = getRouteManifests()

  // Extract all remote definitions from the manifests
  const remotes = manifests.flatMap(manifest =>
    manifest.children
      ?.map(child => child.remote)
      .filter((remote): remote is Remote => Boolean(remote))
  )

  // Deduplicate remotes by converting to a Map keyed by `module`
  const uniqueRemotes = Array.from(
    new Map(remotes.map(remote => [remote.module, remote])).values()
  )

  return uniqueRemotes
}

export default getRouteManifests()
