import type { RouteLocationNormalized, Router } from 'vue-router'
import { msalInstance, loginRequest } from '@/config/authConfig'
import { InteractionType } from '@azure/msal-browser'

import { useAccountService } from '@/services/accountsService'
import { useCustomerProductPackageService } from '@/services/customerProductPackagesService'

import { useAuthUser } from '@/stores/authUser'
import { useFeatureFlags } from '@/stores/featureFlags'
import { useRouterStore } from '@/stores/router'

import { isAuthenticated } from '@/shared/utils/authUtils'
import { hasRoutePermission } from '@/shared/utils/routeUtils'
import { FEATURE_FLAG } from '@/shared/constants/featureFlags'

import { useI18n } from '@/plugins/i18nPlugin'

import {
  AccountState,
  type UserState,
  UserType
} from '@hms-kontoret/amber.types'
import type { IsAuthenticatedResult } from '@/shared/types'
import { type VueI18n } from 'vue-i18n'

const setDocumentTitle = (to: RouteLocationNormalized) => {
  const i18n = useI18n()
  const { t } = (i18n?.global as VueI18n) ?? {}

  document.title = to.meta.routeTitle
    ? t?.(to.meta.routeTitle) || 'Avonova'
    : 'Avonova'
}

/**
 * Check if the user is being redirected from the login page.
 * @param to
 * @returns True if the user is being redirected from the login page, otherwise false.
 */
const isLoginRedirect = (to: RouteLocationNormalized): boolean => {
  const loginPath = '/app/login'
  return (
    to.redirectedFrom?.path.startsWith(loginPath) ||
    to.path.startsWith(loginPath)
  )
}

/**
 * Check if the user should be redirected to the onboarding flow.
 * @param account
 * @param user
 * @returns True if the user should be redirected to the onboarding flow, otherwise false.
 */
const shouldRedirectToOnboarding = (
  account: AccountState,
  user: UserState
): boolean => {
  const { isFeatureEnabled } = useFeatureFlags()

  if (isFeatureEnabled(FEATURE_FLAG.USE_ONBOARDING_NEEDED))
    return user.onboardingNeeded

  return account.onboardingCompletedDate === null
}

/**
 * Get the path to the onboarding flow based on feature flags.
 */
export const getOnboardingPath = (): string => {
  const { isFeatureEnabled } = useFeatureFlags()

  return isFeatureEnabled(FEATURE_FLAG.USE_ONBOARDING_NEEDED)
    ? '/onboarding/confirm-profile'
    : '/onboarding/onboarding'
}

/**
 * Determine the redirect path after login based on user state.
 * Redirects to the onboarding flow if needed.
 * Redirects to the previous page if the user was redirected from login.
 * Redirects to the service overview if no previous page state is found.
 * @param to
 * @param authResult
 * @returns The path to redirect to after login.
 */
const determineRedirectPath = (
  to: RouteLocationNormalized,
  authResult: IsAuthenticatedResult
): string => {
  const { account, user } = useAuthUser()

  // Redirect based on user state
  if (user.type === UserType.SUPPORT) return to.fullPath
  if (shouldRedirectToOnboarding(account, user)) return getOnboardingPath()

  // If the user was not redirected from login and has a previous page state
  if (
    authResult.state &&
    authResult.state !== '/' &&
    authResult.state !== '/index'
  )
    return authResult.state

  // Default to service overview after login
  return '/home'
}

/**
 * Execute the MFE before-leave guard for the current route.
 * Skips the guard if the route is within the same base path since we only care about cross-MFE navigation.
 * @param to
 * @param from
 * @returns True if the navigation can proceed, otherwise false.
 */
const executeMfeBeforeLeaveGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  if (extractBasePath(to) === extractBasePath(from)) return true
  const { executeGuard } = useRouterStore()
  return executeGuard(to.fullPath, from.fullPath)
}

/**
 * Fetches user data, skipping if already initialized.
 */
const fetchUserData = async () => {
  const { updateWithInitialData, initialDataFetched } = useAuthUser()
  const { getCustomerProductPackage } = useCustomerProductPackageService()
  if (initialDataFetched) return

  const initData = await useAccountService().getInitData()
  const customerProductPackage = await getCustomerProductPackage()

  if (initData) updateWithInitialData(initData, customerProductPackage)
}

/**
 * Extract the base path from a route using the last matched route.
 * @param route
 * @returns The base path of the route.
 */
const extractBasePath = (route: RouteLocationNormalized): string => {
  const matchedRoute = route.matched[route.matched.length - 1]
  return matchedRoute?.path.replace(/:\w+\(\.\*\)\?$/, '')
}

export const authGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  console.log(
    'Amber:AuthGuard',
    to,
    `is inside iframe: ${window !== window.top}`
  )

  // Execute the MFE before-leave guard for the current route.
  // We don't have access to the previous route path via Shell router, so we use window.location.pathname.
  const canNavigate = await executeMfeBeforeLeaveGuard(to, from)
  if (!canNavigate) return false

  // Set document title based on route meta
  setDocumentTitle(to)

  try {
    // Skip authentication check if not required
    if (!to.meta.requiresAuth) return true

    // Check authentication and set redirect state to current path
    const authRequest = { ...loginRequest, state: to.fullPath }
    const authResult = await isAuthenticated(
      msalInstance,
      InteractionType.Redirect,
      authRequest
    )

    if (!authResult.authenticated) {
      // Use MSAL to redirect to the login page
      msalInstance.loginRedirect(authRequest)
      return false // Abort the current navigation as MSAL will take over
    }

    // Fetch user data if not already initialized
    await fetchUserData()

    // Handle redirection for login flow
    if (isLoginRedirect(to)) {
      const redirectPath = determineRedirectPath(to, authResult)

      if (redirectPath !== to.fullPath) return redirectPath
    }

    // Check route permissions, fallback to 'home' if permission is denied
    if (!hasRoutePermission(to)) return '/home'

    // If all checks pass, allow navigation
    return true
  } catch (error: any) {
    console.error('Error during navigation:', error)

    // Default fallback for unexpected errors
    return false
  }
}

const registerGuard = (router: Router) => {
  router.beforeEach(
    (to: RouteLocationNormalized, from: RouteLocationNormalized) =>
      authGuard(to, from)
  )
}

export default registerGuard
