import { msalInstance, loginRequest, msalConfig } from '@/config/authConfig'
import {
  InteractionType,
  PublicClientApplication,
  type AuthenticationResult,
  type RedirectRequest,
  type SilentRequest,
  type AccountInfo
} from '@azure/msal-browser'
import { useMsal, CustomMsalEvent } from '@/plugins/msalPlugin'
import { getCookie } from '@/shared/utils/cookieUtils'
import { formatDuration } from '@/shared/utils/dateUtils'

import { useAuthenticationStore } from '@/stores/authentication'

import type { Amber } from '@hms-kontoret/amber.types'
import type { IsAuthenticatedResult } from '@/shared/types'

// Token structure stored in sessionStorage
interface CachedToken {
  secret: string
}

/**
 * Checks if the user is authenticated through the proxy.
 * @returns {boolean} Whether the user is authenticated through the proxy.
 */
const isProxyLogin = () => window.location.hostname.includes('proxy')

/**
 * Checks if the token should be returned from cache.
 * @returns {boolean} Whether the token should be returned from cache.
 */
const shouldReturnTokenFromCache = () => {
  const { getLastMsalTokenRetrieval } = useAuthenticationStore()

  const lastMsalTokenRetrieval = getLastMsalTokenRetrieval()
  if (!lastMsalTokenRetrieval) return false

  const currentTime = Date.now()
  const timeSinceLastRetrieval = currentTime - lastMsalTokenRetrieval
  console.info(
    'Amber:AuthUtils:shouldReturnTokenFromCache',
    'Time since last token retrieval:',
    formatDuration(timeSinceLastRetrieval),
    `Returning from cache: ${timeSinceLastRetrieval < 10000}`
  )
  return timeSinceLastRetrieval < 10000 // 10 seconds
}

/**
 * Checks if the user is authenticated through the proxy or MSAL.
 * @param {PublicClientApplication} instance The MSAL instance to check authentication with.
 * @param {InteractionType} interactionType The type of interaction to use for authentication.
 * @param {RedirectRequest} loginRequest The login request to use for authentication.
 * @returns {Promise<IsAuthenticatedResult>} The result of the authentication check.
 */
export const tryProxyAuthentication = async (): Promise<string | null> => {
  // Early return if hostname doesn't include 'proxy'
  if (!isProxyLogin()) return null

  const msalToken = useMsal()?.token?.proxy
  if (msalToken) return msalToken

  const cookieToken = acquireProxyAuthenticationTokenFromCache()
  if (!cookieToken) return null

  document.dispatchEvent(
    new CustomEvent(CustomMsalEvent.PROXY_LOGIN, { detail: cookieToken })
  )

  return cookieToken
}

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: RedirectRequest
): Promise<IsAuthenticatedResult> {
  const isProxyAuthenticated = await tryProxyAuthentication()
  if (isProxyAuthenticated) return { authenticated: true }

  const response = await instance.handleRedirectPromise().catch(() => null)
  const accounts = instance.getAllAccounts()

  if (accounts.length > 0)
    return { authenticated: true, state: response?.state ?? null }

  if (interactionType === InteractionType.Redirect) {
    // Redirect immediately without resolving
    await instance.loginRedirect(loginRequest)
    return { authenticated: false }
  }

  return { authenticated: false }
}

/**
 * Acquires an authentication token from MSAL.
 * If the user is authenticated through the proxy, the token is retrieved from the cookie.
 * If the user is not authenticated, the token is acquired through MSAL.
 * If the user is not authenticated, redirects to the login page.
 * @returns {Promise<Amber.API.V1.AuthToken>} The access token and ID token.
 */
export const acquireAuthenticationToken =
  async (): Promise<Amber.API.V1.AuthToken> => {
    console.info('Amber:AuthUtils:acquireAuthenticationToken')

    const silentRequest = { ...loginRequest } as SilentRequest
    const proxyAuthResult = await tryProxyAuthentication()
    if (proxyAuthResult) return { accessToken: '', idToken: proxyAuthResult }

    // Check if token should be returned from cache
    // This is to prevent multiple token requests in a short time span
    if (shouldReturnTokenFromCache()) {
      const cachedToken = acquireAuthenticationTokenFromCache()
      if (cachedToken) return cachedToken
    }

    try {
      const { setLastMsalTokenRetrieval } = useAuthenticationStore()

      const response: AuthenticationResult =
        await msalInstance.acquireTokenSilent(silentRequest)
      const { accessToken, idToken } = response

      // Set last token retrieval time
      setLastMsalTokenRetrieval(Date.now())

      return { accessToken, idToken }
    } catch (error) {
      console.error('Acquire token failed', error)
      throw error
    }
  }

/**
 * NB: Should not be used if not necessary because it might not be up-to-date.
 * Retrieves the token synchronously from MSAL's cache.
 * @returns {Amber.API.V1.AuthToken | null} The access token if found, otherwise null.
 */
export const acquireAuthenticationTokenFromCache =
  (): Amber.API.V1.AuthToken | null => {
    // Check if the user is authenticated through the proxy. If so, return the token from the cookie.
    if (isProxyLogin()) {
      const proxyToken = acquireProxyAuthenticationTokenFromCache()
      console.info(
        'Amber:AuthUtils:acquireAuthenticationTokenFromCache:Proxy',
        proxyToken
      )

      // We don't need to differentiate between access and ID token for the proxy since they are the same
      return proxyToken
        ? { accessToken: proxyToken, idToken: proxyToken }
        : null
    }

    const account: AccountInfo | null = msalInstance.getActiveAccount()
    if (!account) {
      console.warn('No active account found in MSAL cache.')
      return null
    }

    // Retrieve stored token keys
    const msalCacheTokenKeys: string | null = sessionStorage.getItem(
      `msal.token.keys.${msalConfig.auth.clientId}`
    )

    if (!msalCacheTokenKeys) {
      console.warn('No token keys found in sessionStorage.')
      return null
    }

    let parsedKeys: { idToken: string; accessToken: string }
    try {
      parsedKeys = JSON.parse(msalCacheTokenKeys) as {
        idToken: string
        accessToken: string
      }
    } catch (error) {
      console.error('Error parsing token keys from sessionStorage:', error)
      return null
    }

    const { idToken, accessToken } = parsedKeys ?? {}

    if (!idToken || !accessToken) {
      console.warn('No valid ID or access token keys found in sessionStorage.')
      return null
    }

    // Retrieve tokens from sessionStorage
    const idTokenFromCache: string | null = sessionStorage.getItem(idToken)
    const accessTokenFromCache: string | null =
      sessionStorage.getItem(accessToken)

    if (!idTokenFromCache || !accessTokenFromCache) {
      console.warn('Access token or ID token not found in sessionStorage.')
      return null
    }

    let parsedIdToken: CachedToken
    let parsedAccessToken: CachedToken

    try {
      parsedIdToken = JSON.parse(idTokenFromCache) as CachedToken
      parsedAccessToken = JSON.parse(accessTokenFromCache) as CachedToken
    } catch (error) {
      console.error('Error parsing tokens from sessionStorage:', error)
      return null
    }

    console.info('Amber:AuthUtils:acquireAuthenticationTokenFromCache')

    return {
      accessToken: parsedAccessToken?.secret ?? '',
      idToken: parsedIdToken?.secret ?? ''
    }
  }

/**
 * Retrieves the proxy token synchronously from the cookie.
 * @returns {string | null} The ID token if found, otherwise null.
 */
export const acquireProxyAuthenticationTokenFromCache = (): string | null => {
  if (!isProxyLogin()) return null

  const idToken = getCookie('token')
  if (!idToken) return null

  return idToken
}

export const logout = async () => {
  document.dispatchEvent(new CustomEvent(CustomMsalEvent.LOGOUT))
}
